import 'babel-polyfill'
import 'typeface-muli'

import ReactDOM from 'react-dom'

import * as serviceWorker from './serviceWorker'

serviceWorker.unregister();

ReactDOM.render(
  document.location.href='https://eview.eleneo.fr/' 
);

